<template>
  <div class="wrapper" @click="clickOutSide">
    
    <side-bar>

      <template slot="links">
        <sidebar-item
        v-if="isFirstTime == 'false' && kycApprove && haveCard && !profileStatus"
          :link="{
            name: 'Deposit',
            path: '/deposit',
            imageicon: 'deposit',
          }"
        >
        </sidebar-item>


        <sidebar-item
          v-if="isFirstTime == 'false' && kycApprove && !profileStatus"
            :link="{
              name: 'Account Opening',
              path: '/list-account',
              imageicon: 'create-account',
            }"
          >
          <sidebar-item
            v-if="isFirstTime == 'false' && kycApprove && !profileStatus && (!haveCard|| rejectedCard)"
            :link="{
              name: 'Create Account',
              path: '/account-opening'
            }"
          >
          </sidebar-item>
          <sidebar-item
            v-if="isFirstTime == 'false' && kycApprove && !profileStatus && (haveCard || rejectedCard)"
            :link="{
              name: 'List Account',
              path: '/list-account'
            }"
          >
          </sidebar-item>
          <!-- <sidebar-item
            v-if="isFirstTime == 'false'"
            :link="{
              name: 'List Sub Account',
              path: '/list-sub-account'
            }"
          >
          </sidebar-item> -->
        </sidebar-item>

        <sidebar-item
            v-if="isFirstTime == 'false' && kycApprove && haveCard && !profileStatus"
            :link="{
              name: 'Consolidation',
              path: '/consolidation',
              imageicon: 'transfer',
            }"
          >
          </sidebar-item>

          <sidebar-item
            v-if="isFirstTime == 'false' && kycApprove && haveCard && !profileStatus"
            :link="{
              name: 'Exchange',
              path: '/exchange',
              imageicon: 'transfer',
            }"
          >
          </sidebar-item>

        <sidebar-item
            v-if="isFirstTime == 'false' && kycApprove && haveCard && !profileStatus"
            :link="{
              name: 'Withdrawal',
              imageicon: 'withdrawal'
            }"
          >
          <sidebar-item
            v-if="isFirstTime == 'false' && kycApprove && haveCard && !profileStatus"
            :link="{
              name: 'Withdrawal',
              path: '/withdrawal'
            }"
          >
          </sidebar-item>

          <sidebar-item
            v-if="isFirstTime == 'false' && kycApprove && haveCard && !profileStatus"
            :link="{
              name: 'Beneficiaries',
              path: '/beneficiaries'
            }"
          >
          </sidebar-item>

        </sidebar-item>

          
        
        <sidebar-item
          :link="{
            name: 'Settings',
            path: '/settings',
            imageicon: 'settings',
          }"
        >

          <sidebar-item
          v-if="isFirstTime == 'false'"
            :link="{
              name: 'Edit Profile',
              path: '/edit-profile'
            }"
          >
          </sidebar-item>

          <sidebar-item
          v-if="isFirstTime == 'false' && !profileStatus"
            :link="{
              name: 'Upload Documents (eKYC)',
              path: '/kyc',
            }"
          >
          </sidebar-item>

          <sidebar-item
            :link="{
              name: 'Change Password',
              path: '/change-password'
            }"
          >
          </sidebar-item>

          <sidebar-item
            v-if="isPaymentPassword"
            :link="{
              name: 'Set Payment Password',
              path: '/set-payment-password'
            }"
          >
          </sidebar-item>
          <sidebar-item
            v-else
            :link="{
              name: 'Change Payment Password',
              path: '/change-payment-password'
            }"
          >
          </sidebar-item>

          <sidebar-item
            v-if="isFirstTime == 'false'"
            :link="{
              name: 'System Configuration',
              path: '/system-configuration'
            }"
          >
          </sidebar-item>
        </sidebar-item>

        <sidebar-item
          :link="{
            name: 'Logout',
            path: '/logout',
            imageicon: 'logout',
          }"
        >
        </sidebar-item>

      </template>

    </side-bar>
    <div class="main-content">
      <div @click="$sidebar.displaySidebar(false);">
        <div v-if="company_logo" class="d-none d-md-block top-logo-in-content">
          <img alt="Image placeholder" :src="`${publicPath}/img/rcp/${company_logo}.png`" class="mr-5 mt-2 company-logo">
        </div>
        
        <!-- <TopSideMenu class="mb-2"></TopSideMenu> -->
        <notifications position="top right"/>
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view ref="currentPage"></router-view>
        </fade-transition>

        <floating-chat-icon v-if="chat==1 && !visibleChat" icon @toggle-chat="toggleChat" :isActive="showChat"></floating-chat-icon>
        <floating-chat 
          :isFloating="true" 
          v-if="!visibleChat && showChat && chat==1" 
          @toggle-chat="toggleChat"
          @is-connect="isReadyToChat">
        </floating-chat>
      </div>
    </div>
  </div>
</template>
<script>
  /* eslint-disable no-new */
  import PerfectScrollbar from 'perfect-scrollbar';
  import 'perfect-scrollbar/css/perfect-scrollbar.css';
  import Common from "@/services/Common";
  import Member from "@/services/Member";
  import CreateAccount from "@/services/CreateAccount";
  
  import TopSideMenu from "@/components/Navbar/TopSideMenu";
  import FloatingChatIcon from '@/components/Chat/FloatingChatIcon.vue';
  import FloatingChat from "@/components/Chat/Chat.vue";
  import { FadeTransition } from 'vue2-transitions';

  import moment from 'moment';
  

  function hasElement(className) {
    return document.getElementsByClassName(className).length > 0;
  }

  function initScrollbar(className) {
    if (hasElement(className)) {
      new PerfectScrollbar(`.${className}`);
    } else {
      // try to init it later in case this component is loaded async
      setTimeout(() => {
        initScrollbar(className);
      }, 100);
    }
  }

  export default {
    title: 'Dashboard - RC365 V Bank',
    data(){
      return {
        publicPath      : process.env.BASE_URL!='/'?process.env.BASE_URL:'',
        translation     : process.env.VUE_APP_TRANSLATION,
        company_logo    : '',
        domain_info     : {},
        list_logo       : JSON.parse(process.env.VUE_APP_COMPANY_LOGO),
        chat            : process.env.VUE_APP_CHAT,
        domain            : process.env.VUE_APP_DOMAIN,
        visibleChat     : false,
        showChat        : false,
        kyc_data:{
          poi:[
            {
              "id":1,
              "name":"Identity Card",
              "list":[
                {"type":2},
                {"type":3},
                {'type':4}
              ]
            },
            {
              "id":2,
              "name":"Passport",
              "list":[
                {'type':5},
                {'type':6}
              ]
            }
          ]
        },
        isFirstTime : false,
        isPaymentPassword:false,
        isForgetPaymentPassword:false,
        kycStatus:false,
        kycApprove:false,
        profileStatus:false,
        haveCard:false,
        rejectedCard:false
      }
    },
    components: {
      FadeTransition,
      TopSideMenu,
      FloatingChatIcon,
      FloatingChat
    },
    methods: {
      isReadyToChat(is_connect){
        this.$refs['currentPage'].isLoading = !is_connect;
      },
      toggleChat() {
        this.showChat = !this.showChat;
      },
      clickOutSide(evt){
       
      },
      initScrollbar() {
        let isWindows = navigator.platform.startsWith('Win');
        if (isWindows) {
          initScrollbar('sidenav');
        }
      },
      checkKyc(current){
        Common.getKyc().then(response => {
          const data = response.data.data;
          let approve = true;
          
          Object.keys(this.kyc_data).forEach((key) => {
            const doc_list = this.kyc_data[key][current[key]].list;
            for (let i = 0; i < doc_list.length; i++) {
              if(data.kyc[doc_list[i].type].status!=1){
                approve=false;
              }
            }
          });

          if(approve){
            this.kycApprove = true;
            this.kycStatus = false;
          }
          else{
            this.kycStatus = true;
          }

          this.checkRedirect();
          
        }).catch(err => {
          this.checkError(err);
        });
      },
      checkRedirect(){
        this.init = localStorage.getItem("init");
        const link = localStorage.getItem("link");

        if(this.isFirstTime != "false"){
          localStorage.removeItem("init");
          if(this.$route.name!="change-password"){
            this.$router.push({ path: '/change-password' });
          }
        }
        else if(this.isForgetPaymentPassword == true){
          localStorage.removeItem("init");
          if(this.$route.name!="change-payment-password"){
            this.$router.push({ path: '/change-payment-password' });
          }
        }
        else if(this.profileStatus){
          if(this.$route.name!="edit-profile"){
            this.$router.push({ path: '/edit-profile' });
          }
        }
        else if(this.kycStatus && !this.profileStatus){
          localStorage.removeItem("init");
          if(this.$route.name!="kyc"){
            this.$router.push({ path: '/kyc' });
          }
        }
        else if((!this.haveCard || this.rejectedCard) && this.kycApprove && this.$route.name!="account-opening"){
          localStorage.removeItem("init");
          this.$router.push({ path: '/account-opening' });
        }
        else if(!this.profileStatus && this.kycApprove && this.haveCard && this.init == "true" && !link){
          localStorage.removeItem("init");
          // this.$router.push({ path: '/list-account' });
        }
        else{
          localStorage.removeItem("init");
          localStorage.removeItem("link");
        }
      },
      getProfile(){
        this.isFirstTime = localStorage.getItem("isFirstTime");

        Common.profile().then(response => {
          if(response.status == 200 && response.data.success){
            const profile = response.data.data;
            if(profile.domain.indexOf(window.location.hostname)==-1 && this.domain==1){
              localStorage.removeItem("token");
              localStorage.removeItem("isFirstTime");
              window.parent.location.href = profile.domain;
            }
            else{

              if(profile.fullname==null){
                this.profileStatus = true;
              }
              else{
                this.profileStatus = false;
              }

              if(profile.security_code==null){
                this.isPaymentPassword = true;
              }
              else{
                this.isPaymentPassword = false;
              }

              const unixTimestamp = moment().unix();
              if(profile.security_code_expire && unixTimestamp<profile.security_code_expire){
                this.isForgetPaymentPassword = true;
              }
              
              if(this.translation == 1 && profile.language && profile.language!=null){
                localStorage.setItem("lang",profile.language);
                localStorage.setItem("lang-key",profile.language);
              }
              
              if(profile.doc_type!=null && profile.doc_type>0){
                this.checkKyc({"poi":parseInt(profile.doc_type)-1});
              }
              else{
                this.kycStatus=true;
                this.checkRedirect();
              }
            }
          }
        }).catch(err => {
          this.checkError(err);
        });
      },
      inIframe:function(){
          try {
              return window.self !== window.top;
          } catch (e) {
              return true;
          }
      },
      setChatVisible(page){
          if(page == "customer-service"){
            this.visibleChat =true;
          }
          else{
            this.visibleChat =false;
          }
      },
      checkError(err){
        try{
          const response = err.response;            
          if(response.status == 401 && response.data.name == "Unauthorized"){
                  localStorage.removeItem("token");
                  localStorage.removeItem("member");
                  localStorage.removeItem("isFirstTime");
                  this.$router.push({ 
                    path: '/login', 
                  });
                }
          else{
            if(localStorage.getItem("token")){
              var msg = (response.data)?response.data.message:err.message;
              this.$notify({
              type: 'warn',
              text: msg
              })
            }
          }
        }catch(e){
          console.log(err);
        }
      }
    },
    mounted() {

      Object.keys(this.list_logo).forEach((key) => {
        if(window.location.hostname.indexOf(key)!=-1){
          this.company_logo = this.list_logo[key];
        }
      });

      Member.domain().then((response)=>{
          if (response.status == 200) {
              this.domain_info = response.data.data;
          }
      }).catch((err) => {
          this.checkError(err);
      });

      CreateAccount.list().then((response)=>{
        if (response.status == 200) {
          const account = response.data.data;
          if(account){
            account.forEach(element => {
              if(element['information']){
                if(element.status == 1 || element.status == 2){
                  this.haveCard = true;
                }
                else if(element.status == 3){
                  this.rejectedCard = true;
                }
              }
            });
          }

          this.getProfile();
        }
      });

      this.initScrollbar();
      this.setChatVisible(this.$route.name);
    },
    watch:{
        $route (to, from){
            this.setChatVisible(to.name);
        }
    } 
  };
</script>
<style lang="scss">
</style>
