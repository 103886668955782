import Axios from "axios";

// API
const baseURL = (process.env.VUE_APP_ENVIRONMENT == "production")?
  "https://api-va.rcpaylink.com":(process.env.VUE_APP_ENVIRONMENT == "development"?
    "https://gep-api.cgtechnology.io":"http://gep-api.test");
    
const axios = Axios.create({
  baseURL: baseURL,
  headers: {
    Accept: "application/json",
    'Access-Control-Allow-Origin':'*',
    'Cache-Control': 'no-cache',
    "Content-Type": "application/json",
    responseType: "json",
  },
});
axios.interceptors.request.use((config) => {
  const token = localStorage.getItem('token');

  if (!token) {
    return config;
  }

  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

// CURRENCY API
const currencyURL = "https://currency.cgtechnology.io";

const axiosCurrency = Axios.create({
  baseURL: currencyURL,
  headers: {
    Accept: "application/json",
    'Access-Control-Allow-Origin':'*',
    'Cache-Control': 'no-cache',
    "Content-Type": "application/json",
    responseType: "json",
  },
});
axiosCurrency.interceptors.request.use((config) => {
  const token = process.env.VUE_APP_TOKEN_CURRENCY;

  if (!token) {
    return config;
  }

  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

const mockingURL = process.env.BASE_URL !== '/' ? process.env.BASE_URL : '';

const axiosMocking = Axios.create({
  baseURL: mockingURL,
  headers: {
    Accept: "application/json",
    'Access-Control-Allow-Origin':'*',
    'Cache-Control': 'no-cache',
    "Content-Type": "application/json",
    responseType: "json",
  },
});

export {axios,axiosCurrency,axiosMocking};