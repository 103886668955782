import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Layout/AuthLayout.vue';

import NotFound from '@/views/NotFoundPage.vue';

const routes = [
  {
    path: '/',
    redirect: 'list-account',
    component: DashboardLayout,
    children: [
      {
        path: '/kyc',
        name: 'kyc',
        component: () => import('../views/Kyc.vue')
      },
      {
        path: '/resubmit-china',
        name: 'resubmit-china',
        props:route=>({
          user_id:route.query.user_id
        }),
        component: () => import('../views/ResubmitChina.vue')
      },
      {
        path: '/resubmit-hongkong',
        name: 'resubmit-hongkong',
        props:route=>({
          user_id:route.query.user_id
        }),
        component: () => import('../views/ResubmitHongkong.vue')
      },
      {
        path: '/resubmit-international',
        name: 'resubmit-international',
        props:route=>({
          user_id:route.query.user_id
        }),
        component: () => import('../views/ResubmitInternational.vue')
      },
      {
        path: '/deposit',
        name: 'deposit',
        component: () => import('../views/Deposit.vue')
      },
      {
        path: '/withdrawal',
        name: 'withdrawal',
        component: () => import('../views/Withdrawal-Remittance/withdrawal.vue')
      },
      {
        path: '/resubmit-withdrawal',
        name: 'resubmit-withdrawal',
        props:route=>({
          id:route.query.id,
        }),
        component: () => import('../views/Withdrawal-Remittance/ResubmitBeneficiary.vue')
      },
      {
        path: '/resubmit-remittance',
        name: 'resubmit-remittance',
        props:route=>({
          id:route.query.id,
        }),
        component: () => import('../views/Withdrawal-Remittance/ResubmitPartner.vue')
      },
      {
        path: '/update-withdrawal',
        name: 'update-withdrawal',
        props:route=>({
          id:route.query.id,
        }),
        component: () => import('../views/Withdrawal-Remittance/ResubmitWithdrawal.vue')
      },
      {
        path: '/update-remittance',
        name: 'update-remittance',
        props:route=>({
          id:route.query.id,
        }),
        component: () => import('../views/Withdrawal-Remittance/ResubmitRemittance.vue')
      },
      {
        path: '/resubmit-remittance-summary',
        name: 'resubmit-remittance-summary',
        props:route=>({
          id:route.query.id,
        }),
        component: () => import('../views/Withdrawal-Remittance/ResubmitRemittanceSummary.vue')
      },
      {
        path: '/resubmit-withdrawal-summary',
        name: 'resubmit-withdrawal-summary',
        props:route=>({
          id:route.query.id,
        }),
        component: () => import('../views/Withdrawal-Remittance/ResubmitWithdrawalSummary.vue')
      },
      {
        path: '/beneficiaries',
        name: 'beneficiaries',
        component: () => import('../views/Withdrawal-Remittance/Beneficiaries.vue')
      },
      {
        path: '/add-payee',
        name: 'add-payee',
        component: () => import('../views/Withdrawal-Remittance/addPayee.vue')
      },
      {
        path: '/withdrawal-application',
        name: 'withdrawal-application',
        component: () => import('../views/Withdrawal-Remittance/WithdrawalApplication.vue')
      },
      {
        path: '/remittance-application',
        name: 'remittance-application',
        component: () => import('../views/Withdrawal-Remittance/RemittanceApplication.vue')
      },
      {
        path: '/withdrawal-summary',
        name: 'withdrawal-summary',
        component: () => import('../views/Withdrawal-Remittance/WithdrawalSummary.vue')
      },
      {
        path: '/withdrawal-type',
        name: 'withdrawal-type',
        component: () => import('../views/Withdrawal-Remittance/WithdrawalType.vue')
      },
      {
        path: '/add-beneficiary',
        name: 'add-beneficiary',
        component: () => import('../views/Withdrawal-Remittance/addBenificiary.vue')
      },
      {
        path: '/update-beneficiary',
        name: 'update-beneficiary',
        props:route=>({
          id:route.query.id,
        }),
        component: () => import('../views/Withdrawal-Remittance/updateBeneficiary.vue')
      },
      {
        path: '/update-partner',
        name: 'update-partner',
        props:route=>({
          id:route.query.id,
        }),
        component: () => import('../views/Withdrawal-Remittance/updatePartner.vue')
      },
      {
        path: '/update-beneficiary-summary',
        name: 'update-beneficiary-summary',
        props:route=>({
          id:route.query.id,
        }),
        component: () => import('../views/Withdrawal-Remittance/updateBeneficiarySummary.vue')
      },
      {
        path: '/add-partner',
        name: 'add-partner',
        component: () => import('../views/Withdrawal-Remittance/addPartner.vue')
      },
      {
        path: '/partner-bank-info',
        name: 'partner-bank-info',
        component: () => import('../views/Withdrawal-Remittance/partnerBankInfo.vue')
      },
      {
        path: '/resubmit-partner-bank-info',
        name: 'resubmit-partner-bank-info',
        props:route=>({
          id:route.query.id,
        }),
        component: () => import('../views/Withdrawal-Remittance/ResubmitPartnerBankInfo.vue')
      },
      {
        path: '/update-partner-bank-info',
        name: 'update-partner-bank-info',
        props:route=>({
          id:route.query.id,
        }),
        component: () => import('../views/Withdrawal-Remittance/updatePartnerBankInfo.vue')
      },
      {
        path: '/update-partner-summary',
        name: 'update-partner-summary',
        props:route=>({
          id:route.query.id,
        }),
        component: () => import('../views/Withdrawal-Remittance/updatePartnerSummary.vue')
      },
      {
        path: '/beneficiary-summary',
        name: 'beneficiary-summary',
        component: () => import('../views/Withdrawal-Remittance/beneficiarySummary.vue')
      },
      {
        path: '/remittance-summary',
        name: 'remittance-summary',
        component: () => import('../views/Withdrawal-Remittance/RemittanceSummary.vue')
      },
      {
        path: '/add-partner-summary',
        name: 'add-partner-summary',
        component: () => import('../views/Withdrawal-Remittance/addPartnerSummary.vue')
      },
      {
        path: '/set-payment-password',
        name: 'set-payment-password',
        component: () => import('../views/Settings/SetPaymentPassword.vue')
      },
      {
        path: '/change-payment-password',
        name: 'change-payment-password',
        component: () => import('../views/Settings/ChangePaymentPassword.vue')
      },
      {
        path: '/new-payment-password',
        name: 'new-payment-password',
        component: () => import('../views/Settings/NewPaymentPassword.vue')
      },
      {
        path: '/repeat-payment-password',
        name: 'repeat-payment-password',
        component: () => import('../views/Settings/RepeatPaymentPassword.vue')
      },
      {
        path: '/account-opening',
        name: 'account-opening',
        component: () => import('../views/AccountOpening.vue')
      },
      {
        path: '/account-type',
        name: 'account-type',
        props:route=>({
          bank_account:route.query.bank_account
        }),
        component: () => import('../views/AccountType.vue')
      },
      {
        path: '/poi-information',
        name: 'poi-information',
        props:route=>({
          bank_account:route.query.bank_account,
          account_type:route.query.account_type
        }),
        component: () => import('../views/PoiInformation.vue')
      },
      {
        path: '/upload-deposit',
        name: 'upload-deposit',
        props:route=>({
          id:route.query.id
        }),
        component: () => import('../views/UploadDeposit.vue')
      },
      {
        path: '/list-account',
        name: 'list-account',
        component: () => import('../views/listAccount.vue')
      },
      {
        path: '/list-sub-account',
        name: 'list-sub-account',
        props:route=>({
          account_id:route.query.account_id,
          user_type:route.query.user_type
        }),
        component: () => import('../views/listSubAccount.vue')
      },
      {
        path: '/exchange',
        name: 'exchange',
        component: () => import('../views/exchange.vue')
      },
      {
        path: '/consolidation',
        name: 'consolidation',
        component: () => import('../views/transfer.vue')
      },
      {
        path: '/transfer-info',
        name: 'transfer-info',
        component: () => import('../views/transferInfo.vue')
      },
      {
        path: '/transfer-summary',
        name: 'transfer-summary',
        props:route=>({
          user_no:route.query.user_no,
          recipient:route.query.recipient,
          recipientName:route.query.recipientName,
          user_req_no:route.query.user_req_no
        }),
        component: () => import('../views/transferSummary.vue')
      },
      {
        path: '/order-card',
        name: 'order-card',
        component: () => import('../views/Card-Management/first.vue')
      },
      {
        path: '/fill-in-details',
        name: 'fill-in-details',
        component: () => import('../views/Card-Management/second.vue')
      },
      {
        path: '/payment',
        name: 'payment',
        component: () => import('../views/Card-Management/third.vue')
      },
      {
        path: '/complete',
        name: 'complete',
        component: () => import('../views/Card-Management/last.vue')
      },
      {
        path: '/edit-profile',
        name: 'edit-profile',
        component: () => import('../views/EditProfile.vue')
      },
      {
        path: '/system-configuration',
        name: 'system-configuration',
        component: () => import('../views/Settings/SystemConfiguration.vue')
      },  
      {
        path: '/change-password',
        name: 'change-password',
        component: () => import('../views/Settings/ChangePassword.vue')
      },
      {
        path: '/deposit-report',
        name: 'deposit-report',
        component: () => import('../views/DepositReport.vue')
      },
      {
        path: '/transaction',
        name: 'transaction',
        component: () => import('../views/Transaction.vue')
      },
      {
        path: '/cs',
        name: 'customer-service',
        component: () => import('../views/CustomerService.vue')
      },
      {
        path: "/logout",
        name: "logout",
        component: {
          beforeRouteEnter(to, from, next) {
            localStorage.clear();
            location.reload();
          }
        }
      }
    ]
  },
  {
    path: '/',
    redirect: 'login',
    component: AuthLayout,
    children: [
      {
        path: '/login',
        name: 'login',
        meta:{
          noBodyBackground:true
        },
        props:route=>({
          token:route.query.verification,
          register:route.query.register
        }),
        component: () => import('../views/Auth/Login.vue')
      },
      {
        path: '/register',
        name: 'register',
        meta:{
          noBodyBackground:true
        },
        component: () => import('../views/Auth/Register.vue')
      },
      {
        path: '/forgot-password',
        name: 'forgot-password',
        meta:{
          noBodyBackground:true
        },
        component: () => import('../views/Auth/ForgotPassword.vue')
      },
      {
        path: '/resend-email',
        name: 'resend-email',
        meta:{
          noBodyBackground:true
        },
        component: () => import('../views/Auth/ResendEmail.vue')
      },
      {
        path: '/term-condition',
        name: 'term-condition',
        meta:{
          noBodyBackground:true
        },
        component: () => import('../views/TermCondition.vue')
      },
      { path: '*', component: NotFound }
    ]
  }
];

export default routes;
