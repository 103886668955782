<template>
    <input
      id="file-input"
      class="hide-file-input"
      type="file"
      accept="*"
      @change="onFileChange($event)"
    />
</template>
<script>
const action = {
  getFileFormat(file){
      let check_ext = action.getFileExtension(file);
      if(check_ext.indexOf("zip")!=-1){
          return "zip";
      }
      else if(check_ext.indexOf("msword")!=-1){
        return "doc";
      }
      else if(check_ext.indexOf("document")!=-1){
        return "docx";
      }
      else if(check_ext.indexOf("x-compressed")!=-1){
          return "rar";
      }
      else{
          return check_ext;
      }
  },
  getFileExtension(base64String) {
    // Ambil bagian sebelum ";base64" dan hapus "data:" di depannya
    const mimeType = base64String.replace('data:', '').split(';')[0];

    // Ambil bagian setelah "application/"
    const extension = mimeType.split('/')[1];

    return extension;
  },
  valdateImage(file,allowedTypes,size){
    console.log(file);
    let file_format = action.getFileFormat(file.previewBase64);

    if(!allowedTypes){
      allowedTypes = ['jpeg', 'png', 'gif', 'pdf'];
    }
    if(!size){
      size = 10*1024*1024
    }

    if (!file) {
      return "Please select an image file"; 
    }
    else if (!allowedTypes.includes(file_format)) {
      let typeAllow = "";
      for(let i=0;i<allowedTypes.length;i++){
        typeAllow+=allowedTypes[i];

        if(i<allowedTypes.length-2){
          typeAllow+=", ";
        }
        else if(i==allowedTypes.length-2){
          typeAllow+=" and ";
        }
      }
      
      return "Invalid file type. Only "+typeAllow+" are allowed."; 
    }
    else if (file.size > size) {
        return  "File size too large. Maximum size is "+(size/1024/1024)+"MB.";
    }
    else{
      return "";
    }
  }
}

export default {
  props:['allowedTypes','size'],
  methods: {
    onFileChange($event) {
      const file = $event.target.files[0]
      const reader = new FileReader();
      
      reader.readAsDataURL(file)
      reader.onload = () => {
        file.previewBase64 = reader.result;
        const validate = action.valdateImage(file,this.allowedTypes,this.size);
        if(validate == ""){
          this.$emit('file-updated', file)
        }
        else{
          this.$emit('on-error',validate);
        }
      }
      reader.onerror = (error) => {
        
      }
    
    }
  }
}
</script>