import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  mode: 'history',
  scrollBehavior: (to, from ,savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { selector: to.hash };
    }
    return { x: 0, y: 0 };
  }
});

export default router;

router.beforeEach((to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login', '/register', '/forgot-password','/resend-email','/term-condition'];
  const externalLink = ['/upload-deposit'];

  const authRequired = !publicPages.includes(to.path);
  const loginRequired = !externalLink.includes(to.path);

  const loggedIn = localStorage.getItem('token');

  if(loginRequired && !loggedIn){
    localStorage.setItem("link",window.location.href);
  }
  
  if (authRequired && !loggedIn) {
    return next('/login');
  }
  
  // localStorage.removeItem("token");

  next();
})
