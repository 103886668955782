<!-- FloatingChatButton.vue -->
<template>
    <div
        class="floating-chat-button"
        :class="{ 'active': isActive }"
        @click="toggleButton"
    >
      <svg v-if="!isActive" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.0831 3.44238H9.91646C5.66646 3.44238 2.83313 6.27572 2.83313 10.5257V19.0257C2.83313 23.2757 5.66646 26.109 9.91646 26.109V29.1265C9.91646 30.2599 11.1773 30.9399 12.1123 30.3024L18.4165 26.109H24.0831C28.3331 26.109 31.1665 23.2757 31.1665 19.0257V10.5257C31.1665 6.27572 28.3331 3.44238 24.0831 3.44238ZM16.9998 20.6832C16.4048 20.6832 15.9373 20.2015 15.9373 19.6207C15.9373 19.0399 16.4048 18.5582 16.9998 18.5582C17.5948 18.5582 18.0623 19.0399 18.0623 19.6207C18.0623 20.2015 17.5948 20.6832 16.9998 20.6832ZM18.7848 14.804C18.2323 15.1724 18.0623 15.4132 18.0623 15.8099V16.1074C18.0623 16.6882 17.5806 17.1699 16.9998 17.1699C16.419 17.1699 15.9373 16.6882 15.9373 16.1074V15.8099C15.9373 14.1665 17.1415 13.359 17.5948 13.0474C18.119 12.6932 18.289 12.4524 18.289 12.084C18.289 11.3757 17.7081 10.7949 16.9998 10.7949C16.2915 10.7949 15.7106 11.3757 15.7106 12.084C15.7106 12.6649 15.229 13.1465 14.6481 13.1465C14.0673 13.1465 13.5856 12.6649 13.5856 12.084C13.5856 10.1999 15.1156 8.66988 16.9998 8.66988C18.884 8.66988 20.414 10.1999 20.414 12.084C20.414 13.699 19.224 14.5065 18.7848 14.804Z" fill="#fff"/>
      </svg>
      <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.06659 19.9163L0.083252 17.933L8.01659 9.99967L0.083252 2.06634L2.06659 0.0830078L9.99992 8.01634L17.9333 0.0830078L19.9166 2.06634L11.9833 9.99967L19.9166 17.933L17.9333 19.9163L9.99992 11.983L2.06659 19.9163Z" fill="#fff"/>
      </svg>

    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
      };
    },
    props:['isActive'],
    methods: {
      toggleButton() {
        this.$emit('toggle-chat');
      },
    },
    updated(){
    }
  };
  </script>
  
<style scoped src="@/assets/css/chat.css"></style>