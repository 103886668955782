<template>
    <nav class="navbar navbar-vertical fixed-left navbar-expand-md navbar-light bg-nav-rcp" id="sidenav-main">
        <div class="container-fluid">

            <!--Toggler-->
            <navbar-toggle-button @click.native="showSidebar">
                
            </navbar-toggle-button>

            
            <!-- <slot name="mobile-right"> -->
                <ul class="nav align-items-center d-md-none">
                    <!-- <base-dropdown class="nav-item" menu-on-right tag="li" title-tag="a">
                        <a slot="title-container" class="nav-link" href="#" role="button">
                            <div class="media align-items-center">
                              <span class="avatar avatar-sm rounded-circle">
                                <img alt="Image placeholder" :src="`${publicPath}/img/rcp/team-2.png`">
                              </span>
                            </div>
                        </a>

                        <div class=" dropdown-header noti-title">
                            <h6 class="text-overflow m-0">Welcome!</h6>
                        </div>
                        <div class="dropdown-divider"></div>
                        <a @click.stop.prevent="doLogout()" class="dropdown-item">
                            <i class="ni ni-user-run"></i>
                            <span>Logout</span>
                        </a>
                    </base-dropdown> -->
                </ul>
            <!-- </slot> -->
            <slot></slot>
            <div v-show="$sidebar.showSidebar" class="navbar-collapse collapse show" id="sidenav-collapse-main">

                <div class="navbar-collapse-header d-md-none">
                    <div class="row">
                        <div class="col-12 collapse-close">
                            <navbar-toggle-button @click.native="closeSidebar"></navbar-toggle-button>
                        </div>
                    </div>
                </div>

                <ul class="navbar-nav">
                    <slot name="links">
                    </slot>
                </ul>

            </div>
            </div>
    </nav>
</template>
<script>
  import NavbarToggleButton from '@/components/NavbarToggleButton'

  export default {
    name: 'sidebar',
    data(){
        return{
            publicPath      :process.env.BASE_URL!='/'?process.env.BASE_URL:'',
            company_logo    : '',
            list_logo       : JSON.parse(process.env.VUE_APP_COMPANY_LOGO)
        }
    },
    components: {
      NavbarToggleButton
    },
    props: {
      autoClose: {
        type: Boolean,
        default: true,
        description: 'Whether sidebar should autoclose on mobile when clicking an item'
      }
    },
    provide() {
      return {
        autoClose: this.autoClose
      };
    },
    methods: {
      doLogout(){
            localStorage.removeItem("token");
            localStorage.removeItem("member");
            localStorage.removeItem("isFirstTime");
            window.parent.location.href = location.protocol+"//"+window.location.hostname;
      },
      closeSidebar() {
        this.$sidebar.displaySidebar(false)
      },
      showSidebar() {
        this.$sidebar.displaySidebar(true)
      }
    },
    mounted() {
      Object.keys(this.list_logo).forEach((key) => {
        if(window.location.hostname.indexOf(key)!=-1){
          this.company_logo = this.list_logo[key];
        }
      });
    },
    beforeDestroy() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.showSidebar = false;
      }
    }
  };
</script>
