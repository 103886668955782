<template>
    <ul class="nav text-right align-items-center justify-content-end d-none d-md-block mr-4">
          <base-dropdown class="nav-item" menu-on-right tag="li" title-tag="a">
              <a slot="title-container" class="nav-link" href="#" role="button">
                  <div class="media align-items-center">
                    <span class="avatar avatar-sm rounded-circle">
                      <img alt="Image placeholder" :src="`${publicPath}/img/rcp/team-2.png`">
                    </span>
                  </div>
              </a>

              <div class=" dropdown-header noti-title">
                  <h6 class="text-overflow m-0">Welcome!</h6>
              </div>
              <div class="dropdown-divider"></div>
              <a @click.stop.prevent="doLogout()" class="dropdown-item">
                  <i class="ni ni-user-run"></i>
                  <span>Logout</span>
              </a>
          </base-dropdown>
      </ul>
</template>

<script>
export default {
    name: 'top-side-menu',
    data(){
      return {
        publicPath :process.env.BASE_URL!='/'?process.env.BASE_URL:'',
      }
    },
    methods:{
        doLogout(){
            localStorage.removeItem("token");
            localStorage.removeItem("member");
            localStorage.removeItem("isFirstTime");
            this.$router.push({ 
                path: '/login', 
            });
        }
    }
};
</script>
<style></style>