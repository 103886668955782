import { axios,axiosMocking } from '../http-common';

class CreateAccount {
  getCountry(data){
    return axios.get("v1/member/region/get-country",{params:data});
  }
  getProvince(data){
    return axios.get("v1/member/region/get-provinces",{params:data});
  }
  getCity(data){
    return axios.get("v1/member/region/get-city",{params:data});
  }
  getDistrict(data){
    return axios.get("v1/member/region/get-district",{params:data});
  }
  getFile(data){
    return axios.get("v1/member/accounts/get-file",{params:data});
  }
  uploadFile(data){
    return axios.post("v1/member/accounts/upload",data);
  }
  createType(data){
    return axios.post("v1/member/accounts/create",data);
  }
  domestic(data){
    return axios.post("v1/member/accounts/domestic",data);
  }
  hongkong(data){
    return axios.post("v1/member/accounts/hkd",data);
  }
  otherRegion(data){
    return axios.post("v1/member/accounts/create-account-international",data);
  }
  list(){
    return axios.get("v1/member/accounts/get-account");
  }
  listSubAccount(data){
    return axios.get("v1/member/accounts/get-sub-account",{params:data});
  }
  createSubAccount(id){
    return axios.post("v1/member/accounts/request-sub-account/"+id);
  }
  detailSubAccount(data){
    return axios.get("v1/member/accounts/get-sub-account",{params:data});
  }

  exchangeList(data){
    return axios.get("v1/member/accounts/get-exchange-responses",{params:data});
  }

  listCurrency(){
    return axiosMocking.get("/data/currency.json");
  }

  getExchangeRate(data){
    return axios.post("v1/member/accounts/query-rate",data);
  }

  doConvert(data){
    return axios.post("v1/member/accounts/apply-exchange",data);
  }
}

export default new CreateAccount();