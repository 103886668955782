var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",on:{"click":_vm.clickOutSide}},[_c('side-bar',[_c('template',{slot:"links"},[(_vm.isFirstTime == 'false' && _vm.kycApprove && _vm.haveCard && !_vm.profileStatus)?_c('sidebar-item',{attrs:{"link":{
          name: 'Deposit',
          path: '/deposit',
          imageicon: 'deposit',
        }}}):_vm._e(),(_vm.isFirstTime == 'false' && _vm.kycApprove && !_vm.profileStatus)?_c('sidebar-item',{attrs:{"link":{
            name: 'Account Opening',
            path: '/list-account',
            imageicon: 'create-account',
          }}},[(_vm.isFirstTime == 'false' && _vm.kycApprove && !_vm.profileStatus && (!_vm.haveCard|| _vm.rejectedCard))?_c('sidebar-item',{attrs:{"link":{
            name: 'Create Account',
            path: '/account-opening'
          }}}):_vm._e(),(_vm.isFirstTime == 'false' && _vm.kycApprove && !_vm.profileStatus && (_vm.haveCard || _vm.rejectedCard))?_c('sidebar-item',{attrs:{"link":{
            name: 'List Account',
            path: '/list-account'
          }}}):_vm._e()],1):_vm._e(),(_vm.isFirstTime == 'false' && _vm.kycApprove && _vm.haveCard && !_vm.profileStatus)?_c('sidebar-item',{attrs:{"link":{
            name: 'Consolidation',
            path: '/consolidation',
            imageicon: 'transfer',
          }}}):_vm._e(),(_vm.isFirstTime == 'false' && _vm.kycApprove && _vm.haveCard && !_vm.profileStatus)?_c('sidebar-item',{attrs:{"link":{
            name: 'Exchange',
            path: '/exchange',
            imageicon: 'transfer',
          }}}):_vm._e(),(_vm.isFirstTime == 'false' && _vm.kycApprove && _vm.haveCard && !_vm.profileStatus)?_c('sidebar-item',{attrs:{"link":{
            name: 'Withdrawal',
            imageicon: 'withdrawal'
          }}},[(_vm.isFirstTime == 'false' && _vm.kycApprove && _vm.haveCard && !_vm.profileStatus)?_c('sidebar-item',{attrs:{"link":{
            name: 'Withdrawal',
            path: '/withdrawal'
          }}}):_vm._e(),(_vm.isFirstTime == 'false' && _vm.kycApprove && _vm.haveCard && !_vm.profileStatus)?_c('sidebar-item',{attrs:{"link":{
            name: 'Beneficiaries',
            path: '/beneficiaries'
          }}}):_vm._e()],1):_vm._e(),_c('sidebar-item',{attrs:{"link":{
          name: 'Settings',
          path: '/settings',
          imageicon: 'settings',
        }}},[(_vm.isFirstTime == 'false')?_c('sidebar-item',{attrs:{"link":{
            name: 'Edit Profile',
            path: '/edit-profile'
          }}}):_vm._e(),(_vm.isFirstTime == 'false' && !_vm.profileStatus)?_c('sidebar-item',{attrs:{"link":{
            name: 'Upload Documents (eKYC)',
            path: '/kyc',
          }}}):_vm._e(),_c('sidebar-item',{attrs:{"link":{
            name: 'Change Password',
            path: '/change-password'
          }}}),(_vm.isPaymentPassword)?_c('sidebar-item',{attrs:{"link":{
            name: 'Set Payment Password',
            path: '/set-payment-password'
          }}}):_c('sidebar-item',{attrs:{"link":{
            name: 'Change Payment Password',
            path: '/change-payment-password'
          }}}),(_vm.isFirstTime == 'false')?_c('sidebar-item',{attrs:{"link":{
            name: 'System Configuration',
            path: '/system-configuration'
          }}}):_vm._e()],1),_c('sidebar-item',{attrs:{"link":{
          name: 'Logout',
          path: '/logout',
          imageicon: 'logout',
        }}})],1)],2),_c('div',{staticClass:"main-content"},[_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false);}}},[(_vm.company_logo)?_c('div',{staticClass:"d-none d-md-block top-logo-in-content"},[_c('img',{staticClass:"mr-5 mt-2 company-logo",attrs:{"alt":"Image placeholder","src":(_vm.publicPath + "/img/rcp/" + _vm.company_logo + ".png")}})]):_vm._e(),_c('notifications',{attrs:{"position":"top right"}}),_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view',{ref:"currentPage"})],1),(_vm.chat==1 && !_vm.visibleChat)?_c('floating-chat-icon',{attrs:{"icon":"","isActive":_vm.showChat},on:{"toggle-chat":_vm.toggleChat}}):_vm._e(),(!_vm.visibleChat && _vm.showChat && _vm.chat==1)?_c('floating-chat',{attrs:{"isFloating":true},on:{"toggle-chat":_vm.toggleChat,"is-connect":_vm.isReadyToChat}}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }